footer {
  background-color: #f1f1f1;
  padding: 20px 0;
  text-align: center;
  background-color: var(--navy);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10rem;
  padding: 0 8rem;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.footer-content p {
  margin: 0;
  color: var(--lightest-slate);
  text-align: center;
}

.social-links {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  text-align: center;
}

.social-links li {
  display: inline;
  margin: 0 10px;
}

.social-links a {
  text-decoration: none;
  color: #007bff;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #0056b3;
}
