@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');


:root {
	--dark-navy: #020c1b;
	--navy: #0a192f;
	--light-navy: #112240;
	--lightest-navy: #233554;
	--navy-shadow: rgba(2,12,27,0.7);
	--slate: #8892b0;
	--light-slate: #a8b2d1;
	--lightest-slate: #ccd6f6;
	--white: #e6f1ff;
	--green: #64ffda;
	--green-tint: rgba(100,255,218,0.1);
	--font-sans: 'Calibre','San Francisco','SF Pro Text',-apple-system,system-ui,sans-serif;
	--font-mono: 'SF Mono','Fira Code','Fira Mono','Roboto Mono',monospace;
	--fz-xxs: 1.6rem;
	--fz-xs: 1.7rem;
	--fz-sm: 1.8rem;
	--fz-md: 2rem;
	--fz-lg: 2.2rem;
	--fz-xl: 2.4rem;
	--fz-xxl: 2.6rem;
	--fz-heading: 3.6rem;
	--border-radius: .8rem;
	--nav-height: 10.4rem;
	--nav-scroll-height: 7.4rem;
	--tab-height: 4.8rem;
	--tab-width: 12.6rem;
	--easing: cubic-bezier(0.645,0.045,0.355,1);
	--transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
	--hamburger-width: 3.6rem;
	--ham-before: top 0.1s ease-in 0.25s,opacity 0.1s ease-in;
	--ham-before-active: top 0.1s ease-out,opacity 0.1s ease-out 0.12s;
	--ham-after: bottom 0.1s ease-in 0.25s,transform 0.22s cubic-bezier(0.55,0.055,0.675,0.19);
	--ham-after-active: bottom 0.1s ease-out,transform 0.22s cubic-bezier(0.215,0.61,0.355,1) 0.12s;
}
*
{
	margin: 0;
	padding: 0;
	font-family: 'Poppins', sans-serif;
}

.port_card_container{
    position: relative;
}

.card_3d
{
	position: relative;
	width: 300px;
	height: 400px;
	transform-style: preserve-3d;
}
.card_3d .face
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	backface-visibility: hidden;
	transition: 2s ease-in-out;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: perspective(500px) rotateY(0deg);
    border-radius: var(--border-radius);
}
.card_3d .face.front
{
	background: var(--light-navy);
	color: var(--lightest-slate);
}
.card_3d:hover .face.front,.card_3d:focus .face.front
{
	transform: perspective(500px) rotateY(180deg);
}
.card_3d .face.back
{
	background: var(--light-navy);
	color: var(--lightest-slate);
	transform: perspective(500px) rotateY(180deg);
}
.card_3d:hover .face.back,.card_3d:focus .face.back
{
	transform: perspective(500px) rotateY(360deg);
}
.card_3d .face h2
{
	font-size: 4rem;
	transform: perspective(500px) translateZ(50px);
	text-transform: uppercase;
}