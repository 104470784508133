body {
  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: rgba(2, 12, 27, 0.7);
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green: #64ffda;
  --green-tint: rgba(100, 255, 218, 0.1);
  --font-sans: "Calibre", "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif;
  --font-mono: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  --fz-xxs: 1.1rem;
  --fz-xs: 1.3rem;
  --fz-sm: 1.7rem;
  --fz-md: 2rem;
  --fz-lg: 2.2rem;
  --fz-xl: 2.4rem;
  --fz-xxl: 2.6rem;
  --fz-heading: 3.6rem;
  --border-radius: 0.8rem;
  --nav-height: 10.4rem;
  --nav-scroll-height: 7.4rem;
  --tab-height: 4.8rem;
  --tab-width: 12.6rem;
  --easing: cubic-bezier(0.645, 0.045, 0.355, 1);
  --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  --hamburger-width: 3.6rem;
  --ham-before: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
  --ham-before-active: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
  --ham-after: bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ham-after-active: bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  --hum__color: var(--green);
  --hum_width: 4rem;
  --hum_height: 0.2rem;
  --hum_before_width: 3rem;
  --hum_before_height: 0.2rem;
  --hum_after_width: 5rem;
  --hum_after_height: 0.2rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  background: var(--navy);
  font-family: var(--font-mono);
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.port_container {
  margin: 0 25rem;
}

button:hover {
  background-color: var(--green-tint);
  cursor: pointer;
}
.p_nav {
  background-color: var(--navy);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10rem;
  padding: 0 8rem;
  position: fixed;
  width: 100%;
  z-index: 1000;
}
.p_brand {
  margin-left: 4rem;
  font-size: var(--fz-sm);
  color: var(--green);
  z-index: 1000;
}

.p_brand h4 {
  width: 3.5rem;
  height: 4rem;
  border: 0.2rem solid;
  /* border-radius: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 0;
  border-right: 0;
  border-top-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
}

.p_brand h4 {
  width: 4rem;
  height: 4rem;
  border: 0.2rem solid;
  /* border-radius: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 0;
  border-right: 0;
  border-top-left-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem;
  border-top-width: 0.4rem;
  border-bottom-width: 0.4rem;
  padding: 2rem 4rem;
  font-size: 3rem;
}

.p_brand h4 {
  width: 4rem;
  height: 4rem;
  border: 0.2rem solid;
  /* border-radius: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 0;
  border-right: 0;
  border-top-left-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem;
  border-top-width: 0.2rem;
  border-bottom-width: 0.2rem;
  padding: 1rem 4rem;
  font-size: 2rem;
}

.p_brand_img {
  width: 11rem;
  height: 11rem;
}
/* border-radius: 50%; */

.p_menu {
  margin-right: 4rem;
  display: flex;
  font-size: 2rem;
  width: auto;
  min-width: 30%;
}

.p_menu a {
  color: var(--lightest-slate);
  text-decoration: none;
  padding: 1rem;
  font-size: var(--fz-xs);
  font-style: var(--font-mono);
}

.p_menu span {
  color: var(--green);
  font-size: var(--fz-xxs);
  margin-right: 0.5rem;
}

.resume_btn {
  background-color: transparent;
  border: 0.1rem solid var(--green);
  color: var(--lightest-slate);
  padding: 0 var(--fz-xxs);
  border-radius: var(--border-radius);
  font-family: var(--font-mono);
  color: var(--green);
  margin-left: 1rem;
}

.load_more_btn {
  background-color: transparent;
  border: 0.1rem solid var(--green);
  color: var(--lightest-slate);
  padding: 1rem 2rem;
  border-radius: var(--border-radius);
  font-family: var(--font-mono);
  color: var(--green);
  margin-left: 1rem;
  margin: auto;
  margin-top: 60px;
  display: flex;
}

.port_btn {
  background-color: transparent;
  border: 0.1rem solid var(--green);
  color: var(--lightest-slate);
  padding: 0 var(--fz-xxs);
  border-radius: var(--border-radius);
  font-family: var(--font-mono);
  color: var(--green);
  /* margin-left: 1rem; */
  min-width: 5rem;
  min-height: 5rem;
  font-size: var(--fz-sm);
}

.hello_btn {
  text-decoration: none;
  background-color: transparent;
  border: 0.1rem solid var(--green);
  color: var(--lightest-slate);
  padding: 10px var(--fz-xxs);
  border-radius: var(--border-radius);
  font-family: var(--font-mono);
  color: var(--green);
  /* margin-left: 1rem; */
  min-width: 5rem;
  min-height: 5rem;
  font-size: var(--fz-sm);
  margin-top: 30px;
}

.code_btn_container {
  margin: 10px 0;
}
.margin_left {
  margin-left: 10px;
}
.code_btn {
  background-color: transparent;
  border: 0.1rem solid #b3efe1;
  /* color: var(--lightest-slate); */
  padding: 2px 18px;
  border-radius: 5px;
  font-family: var(--font-mono);
  color: #cdcdcd;
  /* margin-left: 1rem; */
  min-width: 5rem;
  /* min-height: 5rem; */
  font-size: 14px;
}
/* ##########Hero section */

.port_hero {
  height: auto;
  min-height: 100vh;
  width: auto;
  min-width: 50vw;
  display: flex;
  margin: 0 30rem;
}
.hero_left {
  margin-top: 12rem;
}
.port_hero h3 {
  color: var(--green);
  font-size: clamp(1rem, 2rem, 4rem);
  margin-top: 7rem;
  font-style: var(--font-mono);
  font-weight: 400;
}

.port_hero h2 {
  color: var(--lightest-slate);
  font-size: clamp(4rem, 7rem, 10rem);
  font-style: var(--font-sans);
  margin-top: 1rem;
}

.port_hero h4 {
  font-size: 5.5rem;
  color: var(--slate);
}

.port_hero p {
	font-size: var(--fz-xs);
	color: var(--slate);
	letter-spacing: 0.2em;
	max-width: 45rem;
	margin: 1rem 0 5rem 0;
}

.hum_icon {
  font-size: 4rem !important;
  color: var(--green);
  margin-right: 2rem;
}

.hum_menu {
  display: none;
}

.hum_btn {
  position: relative;
  right: 2rem;
  z-index: 11110;
  display: none;
}

.full_screen {
  width: 100vw;
  min-height: 100vh;
  height: auto;
}
.screen_70 {
  width: 100vw;
  min-height: 70vh;
  height: auto;
}

.make_blur {
  filter: blur(0.3rem);
}

.about_container {
  height: 100vh;
  margin-top: 10rem;
  margin: 0 30rem;
  display: flex;
  flex-direction: column;
}

.about_container h2 {
  color: var(--lightest-slate);
  font-size: 3rem;
}

.about_container h2 span {
  color: var(--green);
  font-size: var(--fz-sm);
  margin-right: 1rem;
}

.content_container {
  display: flex;
  align-items: center;
  font-size: var(--fz-xs);
}

.content_container p {
  color: var(--slate);
  font-size: var(--fz-sm);
}

.content_container p span {
  color: var(--lightest-slate);
}
.about_content {
  width: 80%;
}

.about_ul {
  margin-top: 1rem;
  list-style: none;
}

.li_arrow {
  color: var(--green);
  display: flex;
  align-items: center;
  font-size: 1.4rem;
}
.about_img {
  height: auto;
  position: relative;
}

/* .about_img::before{
  content:"";
  position: absolute;
  background-color:var(--green-tint);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  

} */
.about_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* service Section----------------------------- */
.service_container {
  min-height: 100vh;
  margin: 0 30rem;
}

.service_header {
  display: flex;
  color: white;
  margin: 0 30rem;
  margin-bottom: 7rem;
  text-align: start;
}

.service_header h2 {
  color: var(--lightest-slate);
  font-size: 3rem;
}

.service_header h2 span {
  color: var(--green);
  font-size: var(--fz-sm);
  margin-right: 1rem;
}
.service_cards_container {
  display: flex;
  /* justify-content: space-around; */
  flex-wrap: wrap;
}

.card__container {
  display: flex;
  flex-direction: column;
  color: antiquewhite;
  align-items: center;
  width: 31%;
  margin: 4% 1%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 20rem;
  border-radius: 2rem;
  justify-content: center;
  transition: 0.5s ease-in-out;
}

.card__header {
  color: var(--green);
  font-size: var(--fz-xs);
}
.card__desc {
  text-align: center;
  width: 70%;
  color: var(--light-slate);
  margin: 2rem 0;
}
.card__image {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;
}

.card__image img {
  width: 14rem;
  height: 10rem;
}

.card__image {
  background-color: #020c1b;
  border-radius: 50%;
  width: 15rem;
  height: 15rem;
  transform: translateY(-3rem);
  position: relative;
}

.card__image img {
  width: 14rem;
  height: 10rem;
  transform: translateY(-3rem);
  transition: 0.5s ease-in-out;
}

.card__container:hover {
  background-color: var(--green-tint);
  top: 0px;
  left: 0px;
}

.card__container:hover .card__image {
  transform: translateY(-2rem);
  transition: 0.5s ease-in-out;
}

/* Project_Section_________________________________ */

#projects {
  margin-bottom: 20rem;
}
.project_external_container {
  margin: 0 30rem;
}

.project_header h2 {
  color: var(--lightest-slate);
  font-size: 3rem;
}

.project_header h2 span {
  color: var(--green);
  font-size: 1.5rem;
  margin-right: 1rem;
}

.project_container {
  display: flex;
  align-items: center;
  margin-top: 5rem;
}

.project_container_reverse {
  display: flex;
  align-items: center;
  margin-top: 5rem;
  flex-direction: row-reverse;
}

.project_img {
  width: 60%;
  min-width: 40rem;
  height: 100%;
}
.project_img:hover{
  position: relative;
  z-index: 100;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.project_image_main {
  border-radius: 13px;
  border: 1px solid #164a43ab;
}
.project_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project_desc {
  width: 60%;
  color: var(--light-slate);
  height: 100%;
  /* height: 20rem; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.reverse_project_desc {
  width: 60%;
  color: var(--light-slate);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.project_name {
  font-size: 2rem;
  margin: 2rem 0;
  color: var(--lightest-slate);
}

.project_mini_desc {
  background-color: var(--lightest-navy);
  padding: 4rem 1rem;
  width: 117%;
  display: flex;
  justify-content: flex-end;
  font-size: 1.4rem;
  text-align: end;
}
.reverse_project_mini_desc {
  background-color: var(--lightest-navy);
  padding: 4rem 1rem;
  width: 117%;
  display: flex;
  justify-content: flex-start;
  font-size: 1.4rem;
  text-align: start;
}


.project_tags {
  display: flex;
  list-style: none;
}

.project_tags li {
  /* margin: 0 0rem; */
  padding: 0.5rem 1rem;
  border: 0.1rem solid var(--light-navy);
  border-radius: 12px;
}

.contact_logos {
	display: flex;
	justify-content: center;
	margin-top: 20rem;
}
/* for Tablet Medium Devices */
@media only screen and (max-width: 1100px) {
  .port_hero h2 {
    font-size: 4rem;
  }
}

/* for Mobile devices */
@media only screen and (max-width: 750px) {
  body {
    overflow-x: hidden;
  }

  html {
    font-size: 58.5%;
  }

  .port_container {
    margin: 0 1rem;
  }
  .hero_left {
    margin-top: 10rem;
  }

  .p_nav {
    padding: 0;
  }
  .port_hero {
    margin: 0 4rem;
    flex-wrap: wrap;
  }

  .port_hero h2 {
    font-size: 3.2rem;
  }

  .port_hero h4 {
    font-size: 3.2rem;
  }

  .port_hero p {
    font-size: var(--fz-xxs);
    width: 20rem;
  }

  .p_menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: -48px;
    background: var(--navy);
    width: 85vw;
    align-items: center;
    height: 100vh;
    padding-top: 12rem;
    z-index: 900;
  }

  .p_menu_hide {
    transform: translateX(100%);
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
  }

  .p_menu_show {
    transform: translateX(0%);
    transition: var(--transition);
    visibility: visible;
    opacity: 1;
  }
  .p_menu a {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
    font-size: 1.5rem;
  }

  .hum_btn {
    display: block;
  }

  .set_color {
    color: var(--green);
  }

  .resume_btn {
    padding: 1rem 2rem;
  }

  .about_container {
    margin: 0 4rem;
  }
  .about_content {
    width: 100%;
  }

  .content_container {
    flex-direction: column;
  }

  .about_img {
    width: 100%;
  }

  .service_header {
    margin: 7rem 4rem;
  }

  .service_container {
    margin: 0 4rem;
  }
  .service_cards_container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .card__container {
    width: 80vw;
    margin-bottom: 7rem;
  }

  .project_external_container {
    margin: 0 3rem;
  }
  .project_img {
    width: 60%;
    min-width: 17rem;
    height: 100%;
  }

  .project_name {
    font-size: 1.6rem;
  }
  .project_mini_desc {
    background-color: var(--lightest-navy);
    padding: 1rem .2rem;
    width: 105%;
    display: flex;
    justify-content: flex-end;
    font-size: 0.8rem;
    text-align: end;
  }
  .reverse_project_mini_desc {
    background-color: var(--lightest-navy);
    padding: 1rem .2rem;
    width: 105%;
    display: flex;
    justify-content: flex-end;
    font-size: 0.8rem;
    text-align: end;
  }
  .project_tags li {
    padding: 0.2rem 0.3rem;
    border: 0.1rem solid var(--light-navy);
    border-radius: 7px;
    font-size: 5px;
  }

  .code_btn {
    background-color: transparent;
    border: 0.1rem solid #b3efe1;
    padding: 2px 8px;
    border-radius: 5px;
    font-family: var(--font-mono);
    color: #cdcdcd;
    min-width: 3rem;
    font-size: 5px;
  }
  .project_name {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}
